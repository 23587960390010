.container {
  max-width: 1800px;
  width: calc(100% - 128px);

  margin: 0 auto;

  padding: 200px 64px;
}

.videos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;

  width: 100%;
  height: fit-content;

  margin-top: 2rem;
}

.videoFrame {
  width: 100%;
  aspect-ratio: 16/9;
}

.video {
  position: relative;

  height: fit-content;
  width: calc(50% - 1rem);
}

.videoLabel {
  margin: 0;

  color: #fff;
  font-family: "Trim", sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
  font-size: 1rem;

  margin: 0.75rem 0 0 1rem;
}

@media screen and (max-width: 900px) {
  .container {
    padding: 200px 32px;
    width: calc(100% - 64px);
  }

  .video {
    width: 100%;
  }
}
