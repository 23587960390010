.w {
  width: 100%;
  height: auto;
}

.h {
  width: auto;
  height: 100%;
}

.backgroundVideo,
.phoneVideo {
  position: absolute;

  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);

  filter: blur(7.5px);
  background: black;
}
