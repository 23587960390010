.hero {
  width: calc(100% - 64px);
  max-width: 1200px;

  padding: 200px 32px 100px 32px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 64px;
}

.heroImage {
  width: 100%;
  max-width: 500px;
  aspect-ratio: 5/3.5;
  height: fit-content;

  transform: rotate(3deg);

  position: relative;

  background-size: cover;
  background-position: center;
}

.heroImage::before {
  content: "";

  position: absolute;

  max-width: 200px;
  width: 50%;
  aspect-ratio: 200/48;
  height: auto;

  top: -24px;
  left: 50%;
  transform: translateX(-50%) rotate(-3deg);

  background: url("/images/tape.webp");
}

.instagram {
  display: flex;
  flex-direction: row;
  align-items: center;

  width: fit-content;
  height: fit-content;

  color: #ddd;
}

.instagram:hover {
  color: #fff;
}

.instagram:hover .instagramIcon {
  filter: contrast(0) brightness(3);
}

.instagramIcon {
  filter: contrast(0) brightness(1.5);
}

.instagramText {
  margin: 0;
  margin-left: 4px;

  font-family: "Trim", sans-serif;
}

@media screen and (max-width: 1100px) {
  .hero {
    align-items: center;
    flex-direction: column;
    row-gap: 100px;

    max-width: 750px;
  }
}
