.header {
  position: relative;

  text-overflow: ellipsis;
  overflow-wrap: break-word;

  max-width: 750px;
  width: 50vw;
  min-width: 250px;
  aspect-ratio: 10/3.5;

  filter: invert(1);

  background-position: center;
  background-size: cover;

  margin: 0;
  z-index: 2;
}

.container {
  position: relative;

  min-height: 100vh;
  height: fit-content;
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  z-index: 3;
}
